import React from 'react';
import { graphql } from 'gatsby';
import SEO from '~/components/seo';
import Layout from '~/components/layout';
import { NakaharaContents } from '~/components/interview/pages/nakaharaContents';
import { InterviewHeader } from '~/components/interview/organisms/InterviewHeader';
import { InterviewData } from '~/components/interview/data';
import { InterviewFooter } from '~/components/interview/organisms/InterviewFooter';
import { HsuContents } from '~/components/interview/pages/hsuContents';

const TestPage: React.VFC = () => {
  const data = InterviewData.hsu;
  return (
    <>
      <SEO title="Staff Interview" />
      <Layout>
        <article style={{ maxWidth: '100vw', overflow: 'hidden' }}>
          <InterviewHeader data={data} />
          <HsuContents />
          <InterviewFooter />
        </article>
      </Layout>
    </>
  );
};
export default TestPage;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
