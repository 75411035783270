import React from 'react';
import { InterviewButton } from '~/components/interview/atoms';
import styled from 'styled-components';
import { InterviewLinks } from '~/components/interview/organisms/InterviewLinks';
import { useIsJapanese } from '~/hooks';
import { media } from '~/styles';

export const InterviewFooter: React.VFC = () => {
  const isJa = useIsJapanese();
  return (
    <footer>
      <FooterInfo>
        <InfoDescription>
          {isJa ? (
            <>
              SAFEHOUSEでのお仕事に興味を持たれた方は、
              <br />
              採用情報をご覧ください。
            </>
          ) : (
            <>
              If you are interested in employment opportunities at SAFEHOUSE
              <br />
              please check out our Careers page.
            </>
          )}
        </InfoDescription>
        <InterviewButton
          to={'/recruit'}
          label={isJa ? '採用情報' : 'Careers'}
        />
      </FooterInfo>
      <FooterMain>
        <FooterHeading>
          {isJa ? (
            <>
              SAFEHOUSEスタッフインタビュー
              <br />
              記事一覧
            </>
          ) : (
            <>SAFEHOUSE Staff interview articles</>
          )}
        </FooterHeading>
        <InterviewLinks />
        <InterviewButton
          to={'/recruit#staffInterview'}
          label={'Back to Interview List'}
        />
      </FooterMain>
    </footer>
  );
};

const InfoDescription = styled.p`
  line-height: 1.6;
  margin-top: 40px;
  ${media.mdUp} {
    font-size: 18px;
  }
  ${media.mdDown} {
    font-size: 15px;
  }
`;

const FooterMain = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-block: 100px;
  gap: 40px;
`;

const FooterInfo = styled.div`
  border-top: 1px solid #333;
  max-width: 816px;
  margin-inline: auto;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 80px;
  margin-top: 80px;
  font-size: 16px;
  line-height: 1.6;
  gap: 24px;
`;

const FooterHeading = styled.h3`
  text-align: center;
  font-weight: normal;
  ${media.mdUp} {
    font-size: 22px;
  }
  ${media.mdDown} {
    font-size: 20px;
  }
`;
