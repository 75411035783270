import React from 'react';
import {
  InterviewContentsWrapper,
  InterviewQuestion,
  InterviewHR,
  InterviewP,
  InterviewTalk,
  InterviewAnswer,
  InterviewH2,
  InterviewImg,
} from '~/components/interview/atoms';
import hsuImg1 from '~/assets/images/interview/hsu_img1.jpg';
import hsuImg2 from '~/assets/images/interview/hsu_img2.jpg';
import { useIsJapanese } from '~/hooks';

export const HsuContents = () => {
  const isJapanese = useIsJapanese();
  return (
    <InterviewContentsWrapper>
      {isJapanese ? (
        <>
          <InterviewP>
            Blizzard
            Entertainmentのシネマティックチームで、『オーバーウォッチ』や『ディアブロ』など数々の人気ゲームのアニメーション制作に携わり、2023年10月にSAFEHOUSEのスーパーバイザーとしてジョインしたレイ・シュー（Ray
            Hsu）。
            <br />
            <br />
            SAFEHOUSE代表作『機動戦士ガンダム
            復讐のレクイエム』のアニメーション制作にも携わる他、自身が代表を務めるスタジオANIMGURUでは、若手アニメーターの教育も行うなど、精力的な活動を続けています。そんな彼にこれまでのキャリアについてや、普段どんな想いで制作に取り組んでいるかを訊ききました。
          </InterviewP>
          <InterviewHR />
          <InterviewTalk>
            <InterviewQuestion>
              こんにちは、レイさん。まずは自己紹介をお願いします。
            </InterviewQuestion>
            <InterviewAnswer>
              こんにちは皆さん！
              私はレイです。私は現在SAFEHOUEのアニメーションチームでスーパーバイザーを勤めており、台湾のアニメスタジオANIMGURUのCEOでもあります。私はソニー・ピクチャーズ・イメージワークスでアニメーターとしてのキャリアをスタートしました。『アルビンとチップマンクス』や『スマーフ』といった人気アニメ映画作品の制作に携わった後、熱意と興味のあったゲーム業界への転身を考えて、Blizzard
              Entertainmentのシネマティックチームに加わりました。そこで9年間在籍して、『オーバーウォッチ』、『World
              of Warcraft』、『StarCraft』、『Diablo
              III』といった作品のアニメーションを制作したのです。
            </InterviewAnswer>
          </InterviewTalk>
          <InterviewHR />
          <InterviewH2>
            Blizzardでは完成度の高め方を学びました。ディテールが作品の良し悪しを決めるんです
          </InterviewH2>
          <InterviewTalk>
            <InterviewQuestion>
              アニメーションチームではどのような人が求められていますか？
            </InterviewQuestion>
            <InterviewAnswer>
              まず、その人のデモリールをよく確認します。それから、一緒に楽しく仕事ができるような人柄か性格も見るようにしています。才能を持ち合わせた人との仕事はワクワクするし、冗談を言い合えるチームでは楽しく仕事ができますよね。アニメーターは常に話し合いを重ねながら仕事をしているので、コミュニケーション能力は大切なんです。私は過去にピクサーのクラスに通っていたのですが、指導担当は2人とも仕事に関しては天才的で、ユーモアに溢れた人でした。彼らは普段の生活でも楽しむことを忘れません。私が才能と面白さの両方を兼ね備えた人と働きたいと思うのは、その影響もあるかもしれません。
            </InterviewAnswer>
          </InterviewTalk>
          <InterviewTalk>
            <InterviewQuestion>
              Blizzardのシネマティックチームとの仕事から何を学びましたか？
            </InterviewQuestion>
            <InterviewAnswer>
              作品の完成度を高める方法について多くのことを学びました。ひとつ良いエピソードがあるのでお話ししましょう。入社してすぐ『StarCraft』の「Zerg」というエイリアン型キャラクターの動きを担当することになりました。映画では通常、こういった作業には長くても4時間しかもらえません。しかし、この時のスーパーバイザーは10時間もかけてよいと言ってくれました。四肢動物の動きを解剖学的に、よく理解して欲しかったのだと思います。それから、たくさんの参考資料も与えてくれました。結局「Zergの動き」には完成まで1週間もかかってしまいましたが、このプロセスで多くのことを学びました。特にディテールにどれだけ注力すべきかについて知るよい機会になりました。
              <br />
              <br />
              スーパーバイザーとしてチームメンバーを指導するようになってからは、Blizzardのシネマティック・アーティストとしてどのようにあるべきか、より真摯に考えるようになりました。些細な動きが作品に大きな効果を与えることもあります。Blizzard退社後も一つひとつの場面の完成度を高め、作品のクオリティを上げるために毎日取り組んでいます。
            </InterviewAnswer>
            <InterviewImg
              src={hsuImg1}
              caption={
                'Credit: Blizzard Entertainment / Overwatch Animated Short | "Recall"'
              }
            />
          </InterviewTalk>
          <InterviewTalk>
            <InterviewQuestion>
              アニメーターになるきっかけになった映画やテレビシリーズはありますか？
            </InterviewQuestion>
            <InterviewAnswer>
              映画『ロード・オブ・ザ・リング』です（笑）。実はアニメーターとして成功したいとは、元々思っていなかったんです。大学在学中は映像について広く学んでいましたが、人体などのモデリングが得意でした。ただ、アニメーションは全授業の中で一番苦手で、初めて作ったアニメーションは酷くて、まるでゾンビ……よりももっとひどい動きでした。その頃、すでに大きな映画会社での就職が決まっていた大学院生と知り合いました。作品を見せてもらったのですが、本当に素晴らしくて。画面から目を離すことができず、何度も繰り返して映像を観ました。どうすればこんな作品が作れるのか知りたかったのです。
              幸運だったのはピクサーのクラスで教えてくれていた指導員が、どうすればアニメーションとして作ることができるかを教えてくれたことです。これがきっかけでアニメーターになる決断をしました。
            </InterviewAnswer>
          </InterviewTalk>
          <InterviewHR />
          <InterviewH2>
            メンバーにとって良きメンターでありたい。迷った時はジブリやピクサーの作品を見返します
          </InterviewH2>
          <InterviewTalk>
            <InterviewImg src={hsuImg2} />
            <InterviewQuestion>
              どんなアニメ作品に影響を受けましたか？
            </InterviewQuestion>
            <InterviewAnswer>
              ジブリやピクサーの作品には大きな影響を受けてますし、今でも大ファンです。キャラクターが生き生きしていて、ストーリーがインスピレーションを与えてくれます。アニメーションの名作はたくさんありますけど、仕事で何か疑問を感じた時に戻ってきて見るのは、この二つのスタジオの作品ですね。何度見返したか数え切れません。
            </InterviewAnswer>
          </InterviewTalk>
          <InterviewTalk>
            <InterviewQuestion>
              スーパーバイザーとして、さまざまなタイプの人と接することになるかと思います。人をまとめるリーダーとして、最も大切なことは何でしょうか？
            </InterviewQuestion>
            <InterviewAnswer>
              アーティストにはそれぞれ個性があり、それがアーティストたらしめていると思います。スーパーバイザーとしては指導者というよりも、メンターのような存在でありたいですね。メンバーたちが学び続け、それぞれの分野で力を発揮することを願っています。たとえ別の仕事に就いたとしても、私たちから指導を受けたと言うことができますしね。みなさんご存知の通り、スタジオは忙しいのでたくさん話をしたり、レクチャーすることはできません。ただ、カットは必ず修正しますし、その理由もちゃんと説明します。アーティストとしては、何度も直しを出されるのは辛いかもしれませんが、良い作品づくりのためには不可欠です。修正指示を通して、成長を促し、気づきを与えられると良いと思っています。
            </InterviewAnswer>
          </InterviewTalk>
          <InterviewTalk>
            <InterviewQuestion>
              仕事で最もやりがいを感じるのはどんなところですか？
            </InterviewQuestion>
            <InterviewAnswer>
              最もやりがいを感じるのは、私の作品を見た観客のリアクションを見ることです。観客は作品を通して反応したり、何かを得てくれます。だから、エンターテインメントって言うんですよね？　良いアニメーションを作り、観客がそれを楽しむ。私たちの作品づくりが正しければ、素晴らしさを感じてもらえるでしょう。ここが一致するかが、私にとってのやりがいです。自分の関わった作品が公開されて、それを楽しみにしていたり、気に入っている様子が伝わってくるようなコメントを見るときが何よりも幸せな瞬間です！
            </InterviewAnswer>
          </InterviewTalk>
          <InterviewTalk>
            <InterviewQuestion>
              インタビューは以上です。最後に現役のアニメーターや、これからアニメーターを目指す人に向けてメッセージをお願いします。
            </InterviewQuestion>
            <InterviewAnswer>
              どうもありがとうございます！
              このインタビューを見てくれた人と、将来一緒にアニメーションの仕事ができることを楽しみにしています。
            </InterviewAnswer>
          </InterviewTalk>
        </>
      ) : (
        <>
          <InterviewP>
            Ray Hsu joined SAFEHOUSE as an animation supervisor in October 2023,
            after working in the cinematic team at Blizzard Entertainment, where
            he was involved in the animation production of many popular games
            such as "Overwatch" and "Diablo.” He has also been involved in the
            animation production of one of SAFEHOUSE's biggest productions, and
            continues to work tirelessly as a trainer for young animators at
            ANIMGURU, the studio where he is currently serving as CEO. We asked
            him about his career to date and his thoughts on his work.
          </InterviewP>
          <InterviewHR />
          <InterviewTalk>
            <InterviewQuestion>
              Hi! Can you introduce yourself?
            </InterviewQuestion>
            <InterviewAnswer>
              Hi guys, my name is Ray. I am currently the CEO and animation
              supervisor of Animguru and animation supervisor at SAFEHOUSE. In
              my animation career, I started as an animator at Sony Picture
              Imageworks. “Cloudy with the Chance of Meatballs” was my first
              feature IP in animation. Later, I worked on VFX films “Smurfs” and
              “Alvin and the Chipmunks.” After a few years in the film industry,
              I decided to go into the game industry where my passion and
              interests are. So I joined Blizzard Cinematics and was there for 9
              years.
            </InterviewAnswer>
          </InterviewTalk>
          <InterviewHR />
          <InterviewH2>
            "...it is never about the big moves, it's always about the details
            that make you shine!”
          </InterviewH2>
          <InterviewTalk>
            <InterviewQuestion>
              What kind of people do you look for when bringing them on to your
              animation team?
            </InterviewQuestion>
            <InterviewAnswer>
              I always look at their demo reel first. And the second thing is
              their character and personality. I want to work with someone who
              is fun, someone who is always passionate about what we do in
              animation. Also, if they are passionate about games or films. It's
              always fun to work with talented people and animators. I think
              lots of animators are fun to be with when they make jokes and
              stuff. I remember when I was a student at Pixar classes, my
              mentors were both super talented at their jobs but also really
              funny at making jokes and stuff. So I thought that's the type of
              people I would love to work with together, both talented and fun!
            </InterviewAnswer>
          </InterviewTalk>
          <InterviewTalk>
            <InterviewQuestion>
              What have you learned from working with Blizzard’s cinematic team?
            </InterviewQuestion>
            <InterviewAnswer>
              I learned how polished you can be with your work. I think I
              started to learn more after becoming a supervisor and giving
              feedback on people's work. I realized how to help and guide them
              to achieve the same image that I have in my mind. Lots of artists
              have their own take and thinking in terms of the shot. That's why
              I feel the process becomes very important at this stage. The
              people I was around at Blizzard were all very talented and
              professional! I learned the process of how the Blizzard cinematic
              team achieved something great! I think that's very important to
              me.
              <br />
              <br />I remember my very first task was doing a run cycle of Zerg
              (Starcraft). In the film industry, this is 4 hours of work. And I
              finished early. Then I started to proceed to the next thing, but
              then my Supervisor started pulling out this anatomy sheet of a
              dog's skeleton and reference footage and asked me to really learn
              from the inside out of a 4 legged animal. I was shocked, and then
              I took my time redoing the animation of the dog/Zerg running. In
              the end, the Zerg cycle was finished in 1 week’s time. I was both
              shocked and excited to see the detailed process of Blizzard's
              cinematics team.
              <br />
              <br />
              So like my previous interview with SAFEHOUSE, it is never about
              the big moves, it's always about the details that make you shine!
            </InterviewAnswer>
            <InterviewImg
              src={hsuImg1}
              caption={
                'Credit: Blizzard Entertainment / Overwatch Animated Short | "Recall"'
              }
            />
          </InterviewTalk>
          <InterviewTalk>
            <InterviewQuestion>
              Which animated TV series or movie made you want to be an animator?
            </InterviewQuestion>
            <InterviewAnswer>
              It was “The Lord of the Ring”, haha, I didn’t want to start as an
              animator. I think it was sometime in my college years we got to
              learn everything a bit. I was doing really well with the modeling.
              Also, modeling humans. And Animation was actually the worst out of
              all my classes because to me it was like really hard, animation
              was really hard to do. And I remember my first animation when I
              did it and the movement was basically like a zombie.
              <br />
              <br />
              Maybe even worse than a zombie’s. And then I saw this reel from a
              graduate student who was going to be working at Blue Sky Studios.
              I was like, “Oh my god. How did you make something like that?” So
              I was really surprised and impressed with his work. And then I
              thought to myself, “Oh, I really want to learn this skill and to
              be as good as him.”
              <br />
              <br />
              Yeah, because I remember that when I saw his work, I just kept
              watching it. And I kept replaying it over and over again. I just
              wanted to know, “How did he do this?” And luckily we had a program
              at school where we had Pixar mentors who could really teach us at
              that time. So it was great. And so that’s how I chose to become an
              animator for my career.
            </InterviewAnswer>
          </InterviewTalk>
          <InterviewHR />
          <InterviewH2>
            “...Pixar and Ghibli films. To me, their characters are so alive.”
          </InterviewH2>
          <InterviewTalk>
            <InterviewImg src={hsuImg2} />
            <InterviewQuestion>
              What kind of character animation inspires you?
            </InterviewQuestion>
            <InterviewAnswer>
              I was really inspired by Pixar and Ghibli films. To me, their
              characters are so alive. And the way they tell their stories, they
              are always so inspiring to me. And the details that they put into
              their characters, everything, it’s just beautiful. Even now, in
              the industry, there are so many great animated films, but those
              two studios’ work… I always go back and still watch them over and
              over again. Yeah, I can’t tell you how many times I’ve watched
              Ghibli’s animated films.
            </InterviewAnswer>
          </InterviewTalk>
          <InterviewTalk>
            <InterviewQuestion>
              Being a supervisor you have to interact with many different types
              of personalities. What is the most important thing to keep in mind
              when being a leader around so many people?
            </InterviewQuestion>
            <InterviewAnswer>
              I think all artists have personalities and character which is
              important. I really respect that. It makes them unique even when
              they work. I think all artists have their own style and
              creativity. For me, I wouldn’t say I’m just a leader, it’s more of
              a mentor role: to really guide them, and help them in a way to
              achieve something that I see in my mind (for the project). And
              they can learn throughout this process of how I see things. They
              can train to see how I see things.
              <br />
              <br />
              My mentoring style is to help people to really learn and to be
              better artists. Even if they decide to leave our studio they can
              keep growing as a great artist.
            </InterviewAnswer>
          </InterviewTalk>
          <InterviewTalk>
            <InterviewQuestion>
              What is the most rewarding thing about your job?
            </InterviewQuestion>
            <InterviewAnswer>
              The ultimate rewarding thing is definitely when the audience sees
              your work and reacts. That’s why we call it the entertainment
              business, right? In animation also, we make great art, great
              animation, people see it and they have feelings for it, right? So
              when they see your shot or see your work, they’ll be like, “Oh my
              god, it was so amazing!” To me, that’s the most rewarding part.
              Even with the most recent IP that we saw come out online, people’s
              comments: how they liked it, how it was exciting, looking forward
              to the IP… that’s the greatest feeling.
            </InterviewAnswer>
          </InterviewTalk>
          <InterviewTalk>
            <InterviewQuestion>
              Finally, would you like to say anything to current and aspiring
              animators?
            </InterviewQuestion>
            <InterviewAnswer>
              Thank you! Thanks for checking out this interview. I look forward
              to working with you all in the future.
            </InterviewAnswer>
          </InterviewTalk>
        </>
      )}
    </InterviewContentsWrapper>
  );
};
