import React, { PropsWithChildren } from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby-plugin-react-i18next';
import { media } from '~/styles';

export const InterviewContentsWrapper = styled.div`
  margin-inline: auto;
  display: flex;
  flex-direction: column;
  ${media.mdUp} {
    max-width: 810px;
    gap: 80px;
  }
  ${media.mdDown} {
    margin-inline: 32px;
    gap: 64px;
  }
`;
export const InterviewP = styled.p`
  font-size: 16px;
  color: #b5b5b5;
  line-height: 2;
`;

export const InterviewH2 = styled.h2`
  color: #fff;
  line-height: 1.6;
  font-weight: normal;
  ${media.mdUp} {
    font-size: 30px;
  }
  ${media.mdDown} {
    font-size: 26px;
    gap: 16px;
  }
`;
export const InterviewTalk = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;
export const InterviewQuestion = styled.h3`
  color: #fff;
  margin: 0;
  display: flex;
  font-weight: normal;
  ${media.mdUp} {
    font-size: 22px;
    gap: 24px;
  }
  ${media.mdDown} {
    font-size: 20px;
    gap: 16px;
  }
  &::before {
    content: '';
    display: block;
    height: 1px;
    background-color: #808080;
    flex-grow: 0;
    flex-shrink: 0;
    ${media.mdUp} {
      width: 40px;
      margin-top: 15px;
    }
    ${media.mdDown} {
      width: 16px;
      margin-top: 12px;
    }
  }
`;

export const InterviewAnswer = styled.p`
  font-size: 16px;
  color: #b5b5b5;
  line-height: 2;
`;

export const InterviewHR = styled.hr`
  border: none;
  border-top: 1px solid #333;
  margin: 0;
`;

export const InterviewImg: React.VFC<{ caption?: string; src: string }> = ({
  caption,
  src,
}) => {
  return (
    <InterviewImgFigure>
      <img src={src} alt={caption} />
      {caption && <figcaption>{caption}</figcaption>}
    </InterviewImgFigure>
  );
};

export const InterviewButton: React.VFC<{ to: string; label: string }> = ({
  to,
  label,
}) => {
  return <InterviewButtonStyle to={to}>{label}</InterviewButtonStyle>;
};

const InterviewButtonStyle = styled(Link)`
  display: block;
  padding: 16px 48px;
  border-radius: 100px;
  border: 1px solid #616161;
  color: #ffffff;
  background-color: #1f1f1f;
  width: fit-content;
  transition-duration: 0.3s;

  &:hover {
    border-color: #fff;
    color: #fff;
    background-color: #000000;
    transition-duration: 0.6s;
  }
`;

const InterviewImgFigure = styled.figure`
  overflow: hidden;

  img {
    width: 100%;
    height: auto;
    display: block;
  }

  figcaption {
    font-size: 14px;
    color: #b5b5b5;
    text-align: center;
    margin-top: 8px;
  }
`;
