import React from 'react';
import { Person } from '~/components/interview/data';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import styled from 'styled-components';
import { media } from '~/styles';

export const InterviewHeader: React.VFC<{ data: Person }> = ({ data }) => {
  const { i18n } = useTranslation();
  const lang = i18n.language;
  const info = lang === 'ja' ? data.ja : data.en;
  return (
    <Header>
      <CategoryTitle>Interview</CategoryTitle>
      <Contents>
        <Figure>
          <Img src={info.img} />
        </Figure>
        <Texts>
          <Lead>{info.lead}</Lead>
          <P>
            <Title className="title">{info.title}</Title>
            <Name className="name">{info.name}</Name>
          </P>
        </Texts>
      </Contents>
    </Header>
  );
};

const Header = styled.header`
  display: flex;
  flex-direction: column;
  margin-inline: auto;
  gap: 80px;
  ${media.mdUp} {
    padding-inline: 40px;
    max-width: 1400px;
    margin-bottom: 80px;
  }
  ${media.mdDown} {
    padding-inline: 32px;
    margin-bottom: 64px;
  }
`;
const CategoryTitle = styled.div`
  text-align: center;
  font-weight: normal;
  ${media.mdUp} {
    padding-top: 240px;
    font-size: 60px;
  }
  ${media.mdDown} {
    padding-top: 100px;
    font-size: 38px;
  }
`;

const Contents = styled.div`
  flex-direction: column;
  display: flex;
  ${media.mdUp} {
    gap: 80px;
    align-items: center;
    flex-direction: row;
  }
  ${media.mdDown} {
    gap: 32px;
  }
`;
const Figure = styled.figure`
  width: 100%;
  aspect-ratio: 3 / 2;
  flex-shrink: 0;
  ${media.mdUp} {
    width: 567px;
  }
`;
const Img = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;
const Texts = styled.div`
  display: flex;
  flex-direction: column;
  ${media.mdUp} {
    gap: 40px;
  }
  ${media.mdDown} {
    gap: 28px;
  }
`;
const Lead = styled.h1`
  ${media.mdUp} {
    font-size: 34px;
  }
  ${media.mdDown} {
    font-size: 24px;
  }
`;
const P = styled.p`
  display: flex;
  flex-direction: column;
  ${media.mdUp} {
    gap: 10px;
  }
  ${media.mdDown} {
    gap: 5px;
  }
`;
const Title = styled.span`
  ${media.mdUp} {
    font-size: 18px;
  }
  ${media.mdDown} {
    font-size: 16px;
  }
`;
const Name = styled.span`
  ${media.mdUp} {
    font-size: 30px;
  }
  ${media.mdDown} {
    font-size: 22px;
  }
`;
